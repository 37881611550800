import React, { useState, useEffect, useCallback } from 'react';
import './index.css';
import { workouts } from './workouts';
import VisitorCounter from './VisiterCounter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import bcn from './assets/barcelona.jpg'
import rio from './assets/rio.jpg'
import london from './assets/london.jpg'
import sandiego from './assets/sandiego.jpg'

const App = () => {
  const findWorkoutById = (id) => workouts.find(w => w.id === id) || workouts[0];
  
  const [workout, setWorkout] = useState(findWorkoutById(0));
  const [filter, setFilter] = useState('All');

  const workoutTypes = ['All', ...new Set(workouts.map(w => w.type))];

  const filteredWorkouts = filter === 'All' 
    ? workouts 
    : workouts.filter(w => w.type === filter);

  const generateWorkout = useCallback(() => {
    const availableWorkouts = filteredWorkouts.filter(w => w.id !== workout.id);
    if (availableWorkouts.length > 0) {
      const randomIndex = Math.floor(Math.random() * availableWorkouts.length);
      setWorkout(availableWorkouts[randomIndex]);
    }
  }, [filteredWorkouts, workout.id]);

  useEffect(() => {
    if (filter !== 'All' && !filteredWorkouts.some(w => w.id === workout.id)) {
      generateWorkout();
    }
  }, [filter, filteredWorkouts, workout.id, generateWorkout]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    if (newFilter !== 'All' && !filteredWorkouts.some(w => w.type === newFilter)) {
      generateWorkout();
    }
  };


  return (
    <div className="crossfit-wod">
        <div className="background-slider">
          <img src={bcn} alt="Background 1" />
          <img src={rio} alt="Background 2" />
          <img src={london} alt="Background 3" />
          <img src={sandiego} alt="Background 4" />
        </div>
        <nav className="navbar">
          <span className="gym-icon">
            <VisitorCounter />
          </span>
          <h1 className="title">W O D S . P R O</h1>
          <span className="gym-icon">
            <div className="current-visitors">
              <p className='current-web'>current web</p>
              <p className="visitors-text">VISITORS</p>
              </div>
          </span>
        </nav>
      <div className="content">
        <div className="content-info">
          <p className="join">Fitness Coach, contribute<span> <a rel="noopener noreferrer" target="_blank" href="https://forms.gle/4Ytfn9uQnt8HGvdW9">here!</a></span></p>
          <p className="subtitle">Get your daily workout challenge!!</p>
        </div>
        <div className="filter-buttons">
          {workoutTypes.map(type => (
            <button
              key={type}
              onClick={() => handleFilterChange(type)}
              className={`filter-button ${filter === type ? 'active' : ''}`}
            >
              {type}
            </button>
          ))}
        </div>
        <div className="workout-card">
          <h2>{workout.type} <span className="workoutid">{workout.id}</span></h2>
          <div className="workout-info">
            <span className="workout-time">{workout.time}</span>
          </div>
          <p className="workout-description">{workout.description}</p>
          <button onClick={generateWorkout} className="generate-button">
            Generate New WOD
          </button>
          <div className="author-section">
            <p className="wod-author">WOD designer:</p>
            <div className="author-info">
              <div className="author-flag-details">
                <span className="flag" role="img" aria-label="flag">{workout.flag}</span>
                <div className="author-details">
                  <span className="username">{workout.username}</span>
                  <span className="city"> ({workout.city}) </span>
                </div>
              </div>
            <div className="social-link">
              <p>Follow coach:</p>
                <div className="social-icons">
                { workout.inst ? 
                  <a rel="noopener noreferrer" target="_blank" href={workout.inst}>
                    <FontAwesomeIcon icon={faInstagram} style={{ fontSize: '18px', color: 'red', marginLeft: '6px' }} />
                  </a> 
                : <a rel="noopener noreferrer" href={workout.inst}>
                    <FontAwesomeIcon icon={faInstagram} style={{ fontSize: '18px', color: 'red', marginLeft: '6px' }} />
                  </a> }
                { workout.tik ? 
                  <a rel="noopener noreferrer" target="_blank" href={workout.tik}>
                    <FontAwesomeIcon icon={faTiktok} style={{ fontSize: '18px', color: 'black', marginLeft: '6px' }} />
                  </a>
                : 
                  <FontAwesomeIcon icon={faTiktok} style={{ fontSize: '18px', color: 'black', marginLeft: '6px' }} />
                  }
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
      <h1>Made with &#x2661; in BCN </h1>
      <h1><a className='copyright' href="https://www.wods.pro">www.wods.pro &copy;</a></h1>
      <h1><a className='copyright' href="https://www.giojuliao.com">www.giojuliao.com</a></h1>
      </footer>
    </div>
  );
};

export default App;